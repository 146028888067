import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import Main from './main';
// import Grid from './grid';
import './_grid.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Main>
          <div className="grid-wrapper">
            <div className="header">
            <h1>Edward Johnson</h1>
            <p>
              <a href="https://linkedin.com/in/ejohnsms/">linkedin</a>
            </p>
            </div>
            <div className="main">
            <section>
              <h2>Profile</h2>
              <p>
                Over ten years of experience in the software industry.
                Successfully contributed to every aspect of the software development life-cycle using both my technical and people skills.
                Enjoy working in Agile, collaborative, and transparent environments.
              </p>
            </section>
            <section className="experience">
              <h2>Experience</h2>
              <h3>Frontend Developer, Independent Contractor — 2017—Present</h3>
              <p>
                <ul>
                  <li>Designed, developed, and debugged SPAs for internal tools and customer facing admin portals for billing, notifications, and user workflows.</li>
                  <li>Increased team velocity and visual consistency by creating reusable style and React components for frequently used UI elements.</li>
                  <li>Mentored full-stack devs on frontend best practices.</li>
                </ul>
              </p>
            </section>
            <section className="experience">
            <h3>Frontend Developer, booking.com — 2013-2017</h3>
            <p>
            <ul>
<li>Principal frontend developer for the Room Assignment Grid, a partner facing web application that allowed hoteliers to manage all aspects of room reservations including reservation conflicts, room upgrades, check-in / checkout, and guest request.</li>
<li>Co-developed with one other frontend developer, the Hotel Finder, a client facing web application that helped users find properties based on their specific criteria. This project was used to introduce AngularJS to the development team.</li>
<li>Developed new themes for the hotels’ client facing sites. These themes highlighted the hotel amenities and could be customized by the hotelier.</li>
<li>Mentored backend developers on frontend best practices.</li>
<li>Participated in the recruiting and hiring of new frontend developers.</li>
</ul>
</p>

            </section>
            <section className="experience">
            <h3>Frontend Developer, Limeade — 2010-2013</h3>
            <p>
            <ul>
            <li>Worked closely with PM and designer to create features such as Well-being Assessments and Screenings, Social Challenges, and Coaching, that allowed us to secure critical early adopter clients.</li>
            <li>Improved code quality and maintainability by spearheading the conversion from ASPX to ASP.NET MVC by updating our server side routing and writing the initial models, view, and controllers for the partner portal.</li>
            <li>Mentored backend developers on frontend technologies and implement frontend best practices.</li>
            </ul>
            </p>
            </section>
            <section className="experience">
            <h3>Frontend Developer, MySpace — 2007-2009</h3>
            <p>
               <ul>
                 <li>Worked closely with designers, PMs, and the backend API team to streamline the signup process, which was critical for getting new users and created “sticky” features for the Friends pages to keep the new users on the site.</li>
                 <li>Owned all parts of the MySpace Band Page from sign up and band bio, to the band events calendar. Implemented the “Add to Friends”, “View Friends”, “View Categories”, “Block User” and “Break Friendship” pages. Categories”, “Block User” and “Break Friendship” pages.</li>
              </ul>
            </p>
            </section>
            <section>
            <h2>Education</h2>
            <p>
            <h3>The Evergreen State College, Olympia, WA — Bachelor of Arts, Computer Science</h3>
            </p>
            </section>
            <section>
            <h2>Skills</h2>
              <p>
Productive using modern web development tool sets to create rich user experiences with HTML5, CSS3, and JavaScript. Utilizing task runners (Webpack), frameworks and libraries (React.js, Angular, Lodash, Ramda), CSS preprocessors (SASS, LESS), and TDD. Strong contributor to process improvement and the introduction of new technologies.
              </p>
            </section>
            <section>
            <h2>Certifications</h2>
              <p>
                Certified Scrum Master
              </p>
            </section>
            </div>
          </div>
        </Main>
      </div>
    );
  }
}

export default App;
