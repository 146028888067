import React from 'react';
import Card from './card';
import {IconLinkButton} from './button';

function Main(props) {

    return (
    <main>
        <div className="container">
            {props.children}
        </div>
    </main>
    );
}

export default Main;
